<template>
  <div class="all">
    <p class="title">Are you sure?</p>
    <p class="subtitle">
      Do you really want to delete this item? This process cannot be undone.
    </p>
    <b-button variant="outline-danger" class="red" v-on:click="deleteGroup()"
      >Yes</b-button
    >
    <b-button variant="primary" class="blue" v-on:click="close()">No</b-button>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "DeleteGroup",
  data() {
    return {};
  },
  props: {
    groupId: Number,
  },
  mounted() {
    this.project = this.$store.getters.getProject;
    if (!this.project) {
      this.project = this.$route.params.id;
    }
  },
  methods: {
    deleteGroup() {
      api.deleteGroup(this.project, this.groupId).then(() => {
        this.$bvModal.hide("delete-group");
      });
      this.$emit("delete-group", this.groupId);
    },
    close() {
      this.$bvModal.hide("delete-group");
    },
  },
};
</script>

<style scoped>
.all {
  text-align: center;
  margin-bottom: 40px;
}
.title {
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  text-align: center;
  color: #232c68;
}
.subtitle {
  font-size: 23px;
  line-height: 36px;
  text-align: center;
  color: #232c68;
}
.red {
  width: 170px;
  height: 40px;

  border: 2px solid #f03738;
  border-radius: 52px;
  margin-right: 40px;
  margin-top: 30px;
}
.blue {
  margin-top: 30px;
  width: 170px;
  height: 40px;

  background: #232c68;
  border: 0.5px solid #c1c1c1;
  border-radius: 52px;
}
</style>
