<template>
  <div>
    <sidebar />
    <div class="main">
      <upbar title="" class="pb-5" /> <br />
      <b-alert v-model="showPullErrorAlert" variant="danger">{{
        pullErrorMessage
      }}</b-alert>

      <!-- ---------------------------------- Connectors State List ----------------------------------------->

      <div class="title">
        Connectors State
        <b-button class="btn bg-transparent btn-outline-light" @click="refresh">
          <b-icon icon="arrow-clockwise" variant="dark" scale="1"></b-icon>
        </b-button>

        <b-button class="btn bg-transparent btn-outline-light" @click="display_div = !display_div">
          <b-icon icon="caret-right" variant="dark" scale="1" v-if="display_div"></b-icon>
           <b-icon icon="caret-down" variant="dark" scale="1" v-if="!display_div"></b-icon>
        </b-button>
      </div>

      <br />
      <br />
      <div v-if="display_div" >
      <div class="bar row" >
        <div class="col-1"></div>
        <div class="col-4">
          <p class="tabTitle">Connector Name</p>
        </div>
        <div class="col-3">
          <p class="tabTitle">State</p>
        </div>
        <div class="col-4">
          <p class="tabTitle">Actions</p>
        </div>
      </div>
      <div v-if="endpointStates.length === 0" class="row">
        <p>
          <br />It looks like you don't have any source connectors for this type of migration yet. Please
          <a @click="goToConnectorsPage" class="link">click here</a> to create
          your first connector.
        </p>
      </div>
      <div v-for="con in endpointStates" :key="con.endpointId" class="bar1 row">
        <div class="col-1">
          <img :src="getLogoConnector(con.endpointType)" class="icon" />
        </div>
        <div class="col-4">
          <p class="tabText">{{ con.endpointName }}</p>
        </div>
        <div class="col-3">
          <p class="tabText">{{ con.statusId }}</p>
        </div>
        <div class="col-4">
          <div class="buttons">
            <b-button
              variant="outline-primary"
              class="botton-wide"
              @click="modalEndpointLogs(con.endpointId)"
              >Logs</b-button
            >
            <b-button
              variant="primary"
              class="botton-wide blue ml-3"
              v-on:click="repullConnector(con.endpointId)"
              >Pull Again</b-button
            >
          </div>
        </div>
      </div>
      </div>

      <!-- --------------------------------- Group list ------------------------------ -->

      <div class="top">
        <b-alert v-model="showMigrateErrorAlert" variant="danger">{{
          migrateErrorMessage
        }}</b-alert>
        <div class="mt-4">
          <div class="title">
            Teams and Slack List
            <b-button
              class="btn bg-transparent btn-outline-light"
              @click="refresh"
            >
              <b-icon icon="arrow-clockwise" variant="dark" scale="1"></b-icon>
            </b-button>
          </div>

          <b-dropdown  class="plus-for-migrate mt-5"  text="Migration Actions">
            <b-dropdown-item @click="startMigration">Start</b-dropdown-item>
            <b-dropdown-item @click="stopMigration"> Stop</b-dropdown-item>
          </b-dropdown>
    
           <div class="plus-white-trash mt-5" @click="deleteGroups">
            <b-icon
              class="bicon-trash"
              variant="danger"
              icon="trash-fill"
              aria-hidden="true"
            >
            </b-icon>
            </div>
          <b-input-group class="plus-white mt-5">
            <b-input-group-prepend is-text>
              <b-icon class="altosio-icon" icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              type="search"
              size="lg"
              v-model="search"
              placeholder="Search..."
            ></b-form-input>
          </b-input-group>

          <div class="plus-entries mt-5">
            <div class="plusIn">
              {{groups.length}} entries
            </div>
          </div>
        </div>
      </div>
      <br />
      <br /><br />

      <div class="bar row">
        <div class="col-1">
          <input type="checkbox" class="group1" v-model="selectAll" />
        </div>
        <div class="col-2">
          <p class="tabTitle">
            <label for="vehicle1"> Status </label>
          </p>
        </div>
        <div class="col-1"></div>
        <div class="col-2">
          <p class="tabTitle">Source Name</p>
        </div>
        <!-- <div class="col-1">
          <p class="tabTitle">Email Nickname</p>
        </div> -->
        <div class="col-1"></div>

        <div class="col-2">
          <p class="tabTitle">Target Name</p>
        </div>
        <div class="col-2">
          <p class="tabTitle">Actions</p>
        </div>
      </div>

      <div v-for="group in filteredList" :key="group.id" class="bar1 row">
        <div class="col-1"> 
          <input
            type="checkbox"
            class="group pr-3"
            :value="group.id"
            v-model="selected"
          />
        </div>
        <div class="col-2"  @click="modalLogs(group.id)">
          <p class="tabText">
            <label for="vehicle1"> {{ group.statusId }}</label>
          </p>
        </div>
        <div class="col-1">
          <img :src="getTypeLogo(group.sourceType)" class="icon" />
        </div>
        <div class="col-2" @click="modalLogs(group.id)">
          <p class="tabText">
            {{ group.sourceName }}
          </p>
        </div>
        <!-- <div class="col-1">
          <p class="tabText">
            {{ group.sourceEmail }}
          </p>
        </div> -->
        <div class="col-1">
          <!-- Target icon is always Teams for Slack, and It could be Teams or Group for Teams migrations. -->
          <img
            v-if="group.targetEndpointId != null && group.targetEndpointId != 0 && group.sourceType==15"
            :src="getTypeLogo(group.sourceType==15 ? 6 : group.sourceType)"    
            class="target-icon"
          />
          
    
          <b-icon
            v-else
            class="bicon-questionmark"
            variant="danger"
            icon="question"
            aria-hidden="true"
          />
        </div>
        <div class="col-2">
          <p class="tabText">{{ group.targetName}}</p>
        </div>
        <div class="col-2">
          <div class="buttons">
            <b-button
              variant="outline-primary"
              class="botton"
              v-on:click="modalLogs(group.id)"
              >Details</b-button
            >
            <b-icon
              class="bicon"
              variant="danger"
              icon="trash-fill"
              aria-hidden="true"
              @click="modalDeleteGroup(group.id)"
            >
            </b-icon>
          </div>
        </div>
      </div>

      <b-modal size="xl" id="logs" hide-footer>
        <template #modal-title>
          <span class="modalTitle">Group Details</span>
        </template>
        <groupListDetails
          @update-group-details="updateDetails"
          :groupId="groupId"
        />
      </b-modal>
      <b-modal size="xl" title="Connector logs" id="endpoint-logs" hide-footer>
         <template #modal-title>
          <span class="modalTitle"> Connector logs </span>
        </template>
        <endpointLogs :endpointId="endpointId" />
      </b-modal>
      <b-modal size="xl" id="delete-group" hide-footer>
        <deleteGroup :groupId="groupId" @delete-group="finishGroupDeletion" />
      </b-modal>

      <!-- --------------------------------- Migration Popup ------------------------------ -->

      <b-modal size="xl" id="popup" ref="popup" hide-footer>
        <div>
          <div class="modal-title">Select your destination</div>
          <div class="modal-form">
            <p class="modal-subtitle mt-3">Choose a Target Connector</p>
            <b-form-select
              class="modal-shape col-9"
              v-model="selectedTarget"
              :options="Object.keys(targetsChoices)"
            ></b-form-select>
          </div>

          <div class="modal-button mb-5">
            <b-button
              variant="primary"
              class="modal-botton"
              v-on:click="updateGroups()"
              >Start Migration</b-button
            >
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import sidebar from "../components/sidebar.vue";
import upbar from "../components/upbar.vue";
import api from "../api/index";
import config from "../config/index";
import groupListDetails from "../components/popup/groupListDetails.vue";
import EndpointLogs from "../components/popup/endpointLogs.vue";
import deleteGroup from "../components/popup/deleteGroup.vue";

export default {
  name: "GroupLIst",
  components: {
    sidebar,
    upbar,
    groupListDetails,
    EndpointLogs,
    deleteGroup,
  },
  data() {
    return {
      search: "",
      groups: [],
      logged: false,
      selected: [],
      targetEndpoints: [],
      selectAll: false,
      selectedGroups: [],
      targets: [],
      targetsChoices: {},
      needTargetGroups: [],
      selectedTarget: null,
      targetsGroupsMapping: {},
      endpointStates: [],
      groupId: null,
      endpointId: null,
      project: null,
      pullErrorMessage: "",
      showPullErrorAlert: false,
      showMigrateErrorAlert: false,
      display_div: true,
      migrateErrorMessage: "",
    };
  },
  computed: {
    filteredList() {
      return this.filteredListMethod();
    }
  },
  watch: {
    selected: function (groupIds) {
      this.needTargetGroups= [];
      this.selectedGroups = [];
      for (const groupId of groupIds) {
        const identifiedGroup = this.groups.find((x) => x.id === groupId);
        if (!identifiedGroup.targetEndpointId) {
          this.needTargetGroups.push(identifiedGroup.id);
        }
        this.selectedGroups.push(identifiedGroup.id);
      }
    },
    selectAll: function (val) {
      this.toggleAll(val);
    },
  },
  created() {
    //this.getProjects();
  },
  mounted() {
    this.project = this.$store.getters.getProject;
    if (!this.project) {
      this.project = this.$route.params.id;
    }
    this.loadGroup();
    this.getTargets(this.$route.params.id);
    this.loadEndpointStatus();
    this.timer = setInterval(() => {
      this.refresh();
    }, 60 * 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    filteredListMethod() {
      var groupsToReturn =  this.groups.filter((x) => {
        return JSON.stringify(x)
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
      return groupsToReturn;
    },
    toggleDiv() {
    this.display_div = !this.display_div;
    },    
    goToConnectorsPage() {
      var projectId = this.$route.params.id;
      this.$router.push("/connectors/" + projectId);
    },
    finishGroupDeletion(groupId) {
      const identifiedGroupIndex = this.groups.findIndex((x) => x.id === groupId);
      this.groups.splice(identifiedGroupIndex, 1);
    },
    updateDetails(groupDetails) {
      const identifiedGroup = this.groups.find((x) => x.id === groupDetails.id);
      identifiedGroup.targetName = groupDetails.targetGroupName;
    },
    toggleAll(checked) {
      var itemsWithNoTargetEndpoint = [];
      if(this.search==='')
      {
        //There was NO search query here
          this.selected = checked ? this.groups.map((x) => x.id) : [];
        itemsWithNoTargetEndpoint= this.groups.filter(
            (x) => x.targetEndpointId === null
          );
          this.needTargetGroups = itemsWithNoTargetEndpoint.map((x) => x.id);
      }
      else
      {
        //There was a Search query here
         var searchedGroups = this.filteredListMethod();
         this.selected = checked ? searchedGroups.map((x) => x.id) : [];
          itemsWithNoTargetEndpoint =  searchedGroups.filter(
            (x) => x.targetEndpointId === null
          );
          this.needTargetGroups = itemsWithNoTargetEndpoint.map((x) => x.id);
      }
      
    },
    startMigration() {
      if (this.needTargetGroups.length > 0) {
        this.$refs["popup"].show();
      } else {
        this.updateGroups();
      }
    },
    stopMigration() {
      const projectId = this.$route.params.id;
        for (var j = 0; j < this.selectedGroups.length; j++) {
          var identifiedGroup = this.groups.find(
            (x) => x.id === this.selectedGroups[j]
          );
            const status = { statusId: 8 };
            api.changeGroupStatus(projectId, this.selectedGroups[j], status);
            identifiedGroup.statusId = "Stopping";
        }
        this.selected = [];
    },
    hideModal() {
      this.$refs["popup"].hide();
    },
    modalLogs(groupId) {
      this.groupId = groupId;
      this.$bvModal.show("logs");
    },
    modalEndpointLogs(endpointId) {
      this.endpointId = endpointId;
      this.$bvModal.show("endpoint-logs");
    },
    modalDeleteGroup(groupId) {
      this.groupId = groupId;
      this.$bvModal.show("delete-group");
    },
    deleteGroups() {
      const projectId = this.$route.params.id;
      api.deleteGroups(projectId, this.selectedGroups);
      this.selectedGroups.forEach(element => {
        this.finishGroupDeletion(element);
      });
      this.selected = [];
    },
    updateGroups() {
      const projectId = this.$route.params.id;
      var errorCounter = 0;
      //First, assign the target connector to the Groups that are missing a target connector *IF ANY*
      if (this.needTargetGroups.length > 0) {
        //1- Get the target Endpoint Id
        const IdentifiedTargetEndpoint = this.targetEndpoints.find(
          (x) => x.name === this.selectedTarget
        );
        //2- Run the API Assign Target Update
        const dataToPost = {
          targetEndpointId: IdentifiedTargetEndpoint.id,
          objectsIds: this.needTargetGroups,
        };

        api.assignTargetEndpointForGroups(projectId, dataToPost).then(() => {
          //Once the Target connector is assigned, schedule the migration.
          //3- Sschedule the migration
          const status = { statusId: 7 };
          //3-A Check among the selected Groups, which ones are already working or scheduled
          for (var i = 0; i < this.selectedGroups.length; i++) {
            var identifiedGroup = this.groups.find(
              (x) => x.id === this.selectedGroups[i]
            );
            if (
              identifiedGroup.statusId === "Queued" ||
              identifiedGroup.statusId === "Working"
            ) {
              errorCounter++;
              this.migrateErrorMessage =
                "Failed to start migration on " +
                errorCounter +
                " items. Items already scheduled or working.";
              this.showMigrateErrorAlert = true;
              setTimeout(() => {
                this.showMigrateErrorAlert = false;
              }, 2000);
            } else {
              api.changeGroupStatus(projectId, this.selectedGroups[i], status);
              identifiedGroup.statusId = "Queued";
              identifiedGroup.targetEndpointId = IdentifiedTargetEndpoint.id;
            }
          }
          this.selected = [];
        });
      } else {
        //Selected Groups ALL have a target connector assigned already
        for (var j = 0; j < this.selectedGroups.length; j++) {
          var identifiedGroup = this.groups.find(
            (x) => x.id === this.selectedGroups[j]
          );
          if (
            identifiedGroup.statusId === "Queued" ||
            identifiedGroup.statusId === "Working"
          ) {
            errorCounter++;
            this.migrateErrorMessage =
              "Failed to start migration on " +
              errorCounter +
              " items. Items already scheduled or working.";
            this.showMigrateErrorAlert = true;
            setTimeout(() => {
              this.showMigrateErrorAlert = false;
            }, 2000);
          } else {
            const status = { statusId: 7 };
            api.changeGroupStatus(projectId, this.selectedGroups[j], status);
            identifiedGroup.statusId = "Queued";
          }
        }
        this.selected = [];
      }

      this.$refs["popup"].hide();
    },
    refresh() {
      this.loadGroup();
      this.loadEndpointStatus();
    },
    loadGroup() {
      api.getGroupOverview(this.$route.params.id).then((response) => {
        this.groups = response.responseData;
        for (let item of this.groups) {
          item.statusId = config.STATUS[item.statusId];
        }
      });
    },
    loadEndpointStatus() {
      api.getEndpointStatus(this.$route.params.id, 1).then((response) => {
        this.endpointStates = response.responseData;
        for (let item of this.endpointStates) {
          item.statusId = config.STATUS[item.statusId];
        }
      });
    },

    repullConnector(connectorId) {
      this.pullErrorMessage = "";
      const identifiedConnectorState = this.endpointStates.find(
        (x) => x.endpointId === connectorId
      );
      if (
        identifiedConnectorState.statusId === "Queued" ||
        identifiedConnectorState.statusId === "Working"
      ) {
        //Do not pull
        this.showPullErrorAlert = true;
        this.pullErrorMessage =
          "Cannot repull connector. Connector pull is already queued or working";
        setTimeout(() => {
          this.showPullErrorAlert = false;
        }, 2000);
      } else {
        this.pullErrorMessage = "";
        api.repullConnector(this.project, connectorId);
        identifiedConnectorState.statusId = "Queued";
      }
    },
    getTargets(id) {
      api.getEndpoints(id).then((response) => {
        this.targetEndpoints = response.responseData.filter(
          (connector) => connector.isTarget
        );
        this.targetsGroupsMapping = {};
        for (let item of this.targetEndpoints) {
          this.targetsGroupsMapping[item.id] = [];
          this.targetsChoices[item.name] = item.id;
        }
      });
    },
    getTypeLogo(sourceType) {
      const sourceTypes = {
        6: "./teams.png",
        10:"./groups.png",
        15:"./slack.png"
      };
      var images = require.context("../assets/", false, /\.png$/);
      return images(sourceTypes[sourceType]);
    },
    getLogoConnector(id) {
      const EndpointImages = {
        5: "./teams.png",
        9: "./slack.png"
      };
      var images = require.context("../assets/", false, /\.png$/);
      return images(EndpointImages[id]);
    },
  },
};
</script>
<style scoped>
.main {
  margin-top: 53px;
  margin-left: 370px;
}
.bar {
  margin-top: 53px;
  margin-right: 40px;
  height: 53px;
  border-radius: 2px;
  background: #f2f6ff;
}
.bar1 {
  margin-right: 40px;
  height: 82px;
  border-radius: 2px;
  border-bottom: 2px solid #dfe0eb;
}
.tabTitle {
  margin-top: 14px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 21px;
  color: #2a4192;
}
.tabText {
  margin-top: 28px;
  font-size: 20px;
  line-height: 21px;
  color: #252733;
}
.bicon {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  vertical-align: -10px;
}
.bicon-trash {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  margin-top: 8px;
  vertical-align: -10px;
}
.bicon-questionmark {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  vertical-align: -10px;
  margin-left: 50px;
  margin-top: 20px;
}
.altosio-icon {
  width: 21px;
  height: 36px;
}
.buttons {
  margin-top: 18px;
}
.botton {
  width: 70px;
  height: 44px;
  margin-right: 25px;
}
.botton-wide {
  width: 110px;
  height: 44px;
  margin-right: 25px;
}
.icon {
  margin-right: 20px;
  margin-top: 20px;
}
.target-icon {
  margin-right: 20px;
  margin-left: 50px;
  margin-top: 20px;
}
.plus {
  float: right;
  margin-right: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  width: 268px;
  height: 50px;
  cursor: pointer;
  background: #2a4192;
  border-radius: 5px;
}

.plus-for-migrate {
  float: right;
  margin-right: 40px;
  font-style: normal;
  line-height: 30px;
  width: 180px;
  height: 50px;
  cursor: pointer;
  border-radius: 5px;
}
.plus-white {
  float: right;
  margin-right: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  width: 268px;
  height: 50px;
  cursor: pointer;
  background: #ffffff;
  border-radius: 5px;
}
.plus-white-trash{
  float: right;
  margin-right: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  width: 30px;
  height: 10px;
  cursor: pointer;
  background: #ffffff;
  border-radius: 5px;
}
.plus-entries {
  float: right;
  margin-right: 40px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #2a4192;;
  width: 190 px;
  height: 50px;
  cursor: pointer;
  background: #ffffff;
}
.link {
  color: #2a4192;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
}
.plusIn {
  margin-top: 10px;
  margin-left: 40px;
}
.iconPlus {
  margin-right: 20px;
}
.blue {
  background: #2a4192;
  border-radius: 5px;
}
.title {
  float: left;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.3px;
  margin-top: 48px;
  color: #2a4192;
}
.top {
  margin-top: 60px;
}
.pang {
  position: absolute;
  right: 40px;
}
.pangText {
  position: absolute;
  float: left;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.5);
}
/* ----------------------- */
.group {
  width: 17px;
  height: 17px;
  background-color: gray;
  margin-right: 12px;
  margin-left: 5px;
  margin-top: 28px;
}
.group1 {
  width: 20px;
  height: 20px;
  background-color: gray;
  margin-right: 9px;
  margin-top: 14px;
}
.modal-form {
  margin-left: 28%;
}
.modal-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;

  text-align: center;

  color: #23438e;
}
.modal-subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #23438e;
}
.modal-shape {
  width: 480px;
  height: 56px;
}
.modal-botton {
  margin-top: 30px;
  width: 492px;
  height: 56px;
  background: #23438e;
  border-radius: 5px;
}
.modal-button {
  text-align: center;
}
.modalTitle {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #23438e;
  margin-left: 400px;
  margin-right: auto;
}
</style>
